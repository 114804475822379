import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import supabase from '@/libraries/supabase';
import { useRouter } from 'next/router'
import { registerAccount } from '@/models/userModels';
import Image from 'next/image';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import NextLink from 'next/link';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green } from '@mui/material/colors';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const tiers = [
  {
    title: 'Gratis',
    price: '0',
    period: '',
    description: [
      '1 user tim',
      '1 template landing page',
      'Dukungan fitur gratis baru yang akan berkembang'
    ],
    buttonText: 'Daftar Gratis',
    buttonVariant: 'outlined',
  },
  {
    title: 'Premium',
    subheader: 'Bulanan',
    price: '150.000',
    period: '/bln',
    description: [
      '10 user tim',
      '10 template landing page',
      'Dukungan custom domain',
      'E-commerce dengan 10 produk',
      'Dukungan fitur baru yang akan berkembang'
    ],
    buttonText: 'Mulai',
    buttonVariant: 'contained',
  },
  {
    title: 'Ultimate',
    price: '1.200.000',
    subheader: 'Tahunan',
    period: '/thn',
    description: [
      '10 user tim',
      '10 template landing page',
      'Dukungan custom domain',
      'E-commerce dengan 10 produk',
      'Dukungan fitur baru yang akan berkembang'
    ],
    buttonText: 'Mulai',
    buttonVariant: 'outlined',
  },
];

const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: [
      'Cool stuff',
      'Random feature',
      'Team feature',
      'Developer stuff',
      'Another one',
    ],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];

function RegisterContent() {
  const [user, setUser] = useState(supabase.auth.user() || undefined)
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [registerSuceed, setRegisterSuceed] = useState(false)
  const router = useRouter()
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setIsError(false)
    setLoading(true)

    if (data.get('email') === '' || data.get('password') === '' || data.get('fullname') === '' || data.get('phone') === '') {
      setErrorMessage('All register data cannot be empty')
      setIsError(true)
      setLoading(false)
      return
    }
    let post_data = {
      fullname : data.get('fullname'),
      email : data.get('email'),
      phone : data.get('phone'),
      password : data.get('password')
    }
    const login = await registerAccount(post_data)
    // console.log(login)
    if (login.status === 'ok') {
      // router.push('/')
      setRegisterSuceed(true)
      // setLoading(false)
    } else {
      setErrorMessage(login.error.message)
      setIsError(true)
      setLoading(false)
    }
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const handleRouteLogin = async (event) => {
    router.push('/auth/login')
  }
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="fixed"
        color="primary"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            TREGI.ID
          </Typography>
          <nav>
            
          </nav>
          <Typography variant="body2" align="center" color="text.default" component="p" sx={{ display: { xs: 'none', md: 'block' } }}>
            Sudah punya akun ?
          </Typography>
          <Button href="#" variant="outlined" color="inherit" sx={{ my: 1, mx: 1.5 }} onClick={handleRouteLogin}>
            Login
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="" component="main" sx={{ pt: 12, pb: 6 }}>
        <Grid container spacing={0} alignItems="flex-top" sx={{ backgroundColor: 'none' }}>
          {
            registerSuceed ? 
          <Grid
            item
            xs={12}
            md={6}
            alignItems="center"
            justifyContent="center"
          >
            <center>
            <CheckCircleOutlineIcon sx={{ fontSize: 50, color: green[500], mt:{sm:6, md:15} }} />
            <Typography variant="h5" align="center" component="p" sx={{ color:green[500], width:'70%'}}>
              Pendaftaran telah di proses, silahkan check inbox email anda untuk aktivasi akun
            </Typography>
            </center>
          </Grid>
          :
          <Grid
            item
            xs={12}
            md={6}
            alignItems="center"
            justifyContent="center"
          >
            <center>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: { md: 5, sm: 3 }, width: { md: '60%', sm: '100%' } }}>
                <Typography variant="h5" align="" gutterBottom sx={{ width: { sm: '100%', md: '90%' } }}>
                  Yuk daftar dan bikin landing page mu, <span style={{ color: '#fa7014' }}>GRATIS!</span>
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      autoComplete="given-name"
                      name="fullname"
                      required
                      fullWidth
                      id="fullname"
                      label="Nama Lengkap"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Alamat Email"
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="phone"
                      label="Nomor HP"
                      name="phone"
                      autoComplete="phone"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox value="allowExtraEmails" color="primary" />}
                      label="Saya ingin menerima inspirasi, promosi pemasaran, dan pembaruan melalui email."
                    />
                  </Grid>
                </Grid>
                {isError ? <Alert severity="error" sx={{mt:2}}>{errorMessage}</Alert> : ''}
                {/* <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button> */}
                 {loading ? <LoadingButton
                  loading
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Proses daftar ...
                </LoadingButton> :
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Daftar
                  </Button>
                }
                <Grid container>
                  <Grid item xs>
                    <span>Sudah punya akun ?</span>
                    <NextLink
                      href="/auth/login"
                      passHref
                    >
                      <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                        Login
                      </Button>
                    </NextLink>
                  </Grid>
                </Grid>
              </Box>
            </center>
          </Grid> 
          }
          <Grid
            item
            xs={12}
            md={6}
            alignItems="center"
          >
            <center>
              <Image
                src="https://d33wubrfki0l68.cloudfront.net/5e52122ffae2714c5e7ad412d1e4008b9f54eb30/83e59/static/illustration_hero-0abe6517343b70ab041bf23bb961e915.svg"
                alt="Picture of the author"
                width={400}
                height={300}
              />
              <br />
              <br />
              <Typography variant="h5" align="center" color="text.secondary" component="p">
                <span style={{ color: '#fa7014' }}>TREGI.ID</span> menjadi solusi strategi digital yang paling dicari, dengan landing page generator yang mudah di gunakan
                bisa meningkatkan pengalaman calon pelanggan karena mereka bisa memahami produk anda dengan lebih mudah.
              </Typography>
              <NextLink
                href="/page"
                passHref
              >
                <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                  Lihat Demo
                </Button>
              </NextLink>
            </center>
          </Grid>
        </Grid>
      </Container>
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Harga
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
         Pilihan layanan landing page website mulai dari gratis sampai dengan ultimate untuk mendapatkan 
          layanan terbaik.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      Rp{tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        {/* <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="text.secondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid> */}
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}

export default function Register() {
  return <RegisterContent />;
}