import supabase from '@/libraries/supabase'

const userLogin = async (data) => {

  let response = await fetch('/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  response = await response.json()
  return response
}

const userLogout = async () => {
  const data = { cookie_name: 'sb:token' }
  let response = await fetch('/api/auth/logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  response = await response.json()
  return response
}

const registerAccount = async (data) => {
  let response = await fetch('/api/auth/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  response = await response.json()
  return response
}

const userResetPassword = async (data) => {
  let response = await fetch('/api/auth/reset-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  response = await response.json()
  return response
}

const userNewPassword = async (data) => {
  let response = await fetch('/api/auth/new-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  response = await response.json()
  return response
}

export { registerAccount, userLogin, userLogout, userResetPassword, userNewPassword }